@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

:root {
    --background-dark: #000000;
    --primary-gradient: linear-gradient(to right, #8B5CF6, #EC4899);
    --secondary-gradient: linear-gradient(to bottom right, rgba(139, 92, 246, 0.2), rgba(236, 73, 153, 0.2));
    --text-light: #FFFFFF;
    --text-dark: #000000;
    --text-gray: #9CA3AF;
    --border-radius-lg: 12px;
    --transition-smooth: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    --card-light: rgba(255, 255, 255, 0.1);
    --card-light-solid: rgb(51, 51, 51);
}
.App {
    min-height: 100vh; /* Ensure the app takes up at least the full viewport height */
    display: flex;
    flex-direction: column; /* Stack children vertically */
  }
body {
    font-family: 'Inter', sans-serif;
    background-color: var(--background-dark);
    align-items: center;
    color: var(--text-light);
    line-height: 1.5;
    margin: 0;
    padding: 0;
}
/* Smooth scrolling */
html {
    scroll-behavior: smooth;
  }
/*body.dark-mode {
    background-color: var(--background-dark);
    color: var(--text-dark);
}*/

.container {
    width: 100%;
    margin: 0 auto;
    max-width: var(--max-width);
    padding-top: 4rem;
    flex: 1 ;
}

/* Upload Page */
.upload-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem 1rem;
}

/* Headings */
h1, h2 {
    color: var(--primary-color);
    margin-bottom: 1.5rem;
    text-align: center;
}

/* Buttons */
.btn, button {
    align-items: center;
    justify-content: center;
    background: var(--primary-gradient);
    color: var(--text-light);
    padding: 1rem 2rem;
    border-radius: 9999px;
    text-decoration: none;
    border: none;
    transition: var(--transition);
    font-weight: 500;
    font-size: 1rem; /* Equivalent to text-lg */
    cursor: pointer;
    box-shadow: var(--box-shadow);
  }

btn:hover, button {
    background-color: var(--secondary-color);
    transform: translateY(-2px);
}

.btn-icon {
    margin-right: 0.5rem;
    font-size: 1.2em;
}
.advanced-dropdown{
    align-items: center; /* Center items vertically */
    width: 100%; /* Full width */
    padding: 10px 15px; /* Padding for the button */
    background: #0000; /* Change to your desired color */
    color: white; /* Text color */
    border: none; /* Remove default border */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s;
}
.btn-dropdown:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

.dropdown-text {
    margin-right: 10px; /* Space between text and arrow */
}

.dropdown-arrow {
    transition: transform 0.3s; /* Smooth rotation transition */
}

.dropdown-arrow.rotate {
    transform: rotate(180deg); /* Rotate arrow when dropdown is open */
}
.dropdown-menu {
    border: 1px solid #ccc;
    background-color: white;
    margin-top: 10px; /* Space from the button */
    padding: 5px 0; /* Add some padding */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
/* Navbar */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    backdrop-filter: blur(12px);
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 0 1rem;
    position: relative;
}

.navbar-logo {
    font-size: 2rem; /* Equivalent to text-2xl */
    font-weight: bold; /* Equivalent to font-bold */
    background: var(--primary-gradient); /* Gradient from purple to pink */
    background-clip: text; /* Clips the background to the text */
    -webkit-background-clip: text; /* For Safari compatibility */
    color: transparent; /* Makes the text color transparent */
}


.navbar-menu {
    display: none; 
    position: absolute; 
    top: 100%; 
    right: 0; 
    width: 250px;
    background: rgba(0, 0, 0, 0.8); /* Darker background with some transparency for contrast */
    flex-direction: column;
    padding: 2rem;
    transition: opacity 0.3s ease, visibility 0.3s ease; 
    opacity: 0; 
    visibility: hidden; 
    overflow-y: auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4); 
    border-radius: var(--border-radius-lg); 
}

.navbar-menu.active {
    display: flex; /* Display as flex when active */
    opacity: 1; /* Make it visible */
    visibility: visible; /* Make it visible */
}

@media (max-width: 768px) {
    .navbar-menu {
        width: 100%; 
        height: 100vh; 
        border-radius: 0; 
        background: rgba(0, 0, 0, 0.9); /* Full screen background with more opacity */
        padding: 0 1rem; /* Add horizontal padding to give it some space */
        box-sizing: border-box; /* Ensure padding doesn't affect total width */
    }

    .navbar-menu.active {
        display: flex; 
        opacity: 1; 
        visibility: visible; 
        flex-direction: column; 
        align-items: center; /* Center items horizontally */
    }

    .navbar-item {
        padding: 0; 
        text-align: center; 
        width: 100%; /* Make items full width */
        font-size: 1.5rem;
    }
    .navbar-logo {
      font-size: 1.5rem; /* Equivalent to text-2xl */
      font-weight: bold; /* Equivalent to font-bold */
      background: var(--primary-gradient); /* Gradient from purple to pink */
      background-clip: text; /* Clips the background to the text */
      -webkit-background-clip: text; /* For Safari compatibility */
      color: transparent; /* Makes the text color transparent */
  }
  .btn, button{
    padding: .75rem 1.5rem;
  }
}

.navbar-item {
    display: flex;
    align-items: center;
    padding: 0.75rem 0;
    text-decoration: none;
    color: var(--text-light);
}

.navbar-item svg {
    width: 24px;
    margin-right: 1rem;
}

.navbar-actions {
    display: flex;
    align-items: center;
}

.navbar-burger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    margin: 0rem 1rem;
    transition: transform 0.3s ease; /* Add a transition for a smoother effect */
}

.navbar-burger span {
    width: 2rem;
    height: 0.25rem;
    background: var(--text-light); /* Keep the contrast high */
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
}

.navbar-burger.active span:nth-child(1) {
    transform: rotate(45deg);
}

.navbar-burger.active span:nth-child(2) {
    opacity: 0;
    transform: translateX(20px);
}

.navbar-burger.active span:nth-child(3) {
    transform: rotate(-45deg);
}

.navbar-profile {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    padding-bottom: 10px;
}

.profile-photo,
.default-logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 0.5rem;
    background-color: var(--primary-color);
    color: var(--background-light);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}
.navbar-logo-image {
    height: 30px; /* Adjust size as needed */
    margin-left: 8px; /* Add space between text and image */
    vertical-align: middle; /* Align with text */
}
/* Home Page */
.home-page {
    padding-top: 80px;
}

.hero {
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    color: var(--background-light);
    padding: 3rem 1rem;
    text-align: center;
}

.hero h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: 800;
    background: var(--primary-gradient); /* Gradient from purple to pink */
    background-clip: text; /* Clips the background to the text */
    -webkit-background-clip: text; /* For Safari compatibility */
    color: transparent; /* Makes the text color transparent */
}

.hero p {
    font-size: 1rem;
    margin-bottom: 2rem;
}
.features-grey{
    color: rgba(255, 255, 255, 0.5);;
}
.features {
    padding: 3rem 1rem;
    text-align: center;
    background-color: var(--background-dark);
}

.features h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.75rem;
    font-weight: 700;
}

.feature-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
}

.feature-card {
    padding: 1.5rem; 
    background: var(--card-light); 
    border-radius: var(--border-radius-lg); 
    transition: transform 0.3s;
}

.feature-card h3 {
    color: var(--primary-color);
    margin-bottom: 0.75rem;
    font-size: 1.25rem;
    font-weight: 600;
}

.cta {
    padding: 4rem 0; 
    background: linear-gradient(to right, rgba(139, 92, 246, 0.4), rgba(236, 73, 153, 0.4)); /* Adjusted opacity for a softer effect */
    text-align: center;
}


.cta h2 {
    margin-bottom: 1.5rem;
    font-size: 1.75rem;
    font-weight: 700;
}

/* Login Styles */
.login-container {
    display: flex;
    justify-content: center; /* Center the form */
    align-items: center; /* Center the form vertically */
    padding: 1rem;
    width: 100%; /* Ensure it fills the width */
}

.login-form {
    padding: 2rem; /* Increased padding for a spacious feel */
    border-radius: var(--border-radius); /* Rounded corners */
    box-shadow: var(--box-shadow); /* Shadow for depth */
    width: 100%;
    max-width: 400px; /* Maximum width for the form */
    transition: transform 0.3s ease; /* Smooth transformation */
    text-align: center; /* Center text within the form */
}

.login-form:hover {
    transform: scale(1.02); /* Slight scale effect on hover */
}

.login-form h2 {
    margin-bottom: 1.5rem;
    text-align: center;
}

.login-form input {
    width: 100%;
    padding: 0.75rem; /* Padding inside the input */
    margin-bottom: 1rem; /* Space between inputs */
    border: 1px solid #ccc; /* Light border */
    border-radius: var(--border-radius); /* Rounded corners */
    background: rgba(255, 255, 255, 0.2); /* Slightly transparent background */
    color: var(--text-light); /* Light text color for inputs */
    transition: 0.3s ease; /* Smooth background transition */
}

.auth-link {
    margin-top: 1rem; /* Space above the link */
    text-align: center; /* Center the link */
    color: var(--text-gray); /* Link color */
    text-decoration: none; /* Remove underline */
    font-size: 0.9rem; /* Smaller font size */
}

.auth-link:hover {
    color: var(--text-light); /* Change color on hover */
}

/* Connected Accounts Styles */
.connectedaccounts-container {
    padding: 1rem;
}

.auth-buttons {
    display: grid;
    gap: 1rem;
}
  
  .add-account {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
  
  .add-account select {
    margin-right: 0.5rem;
    padding: 0.5rem;
  }
  
  .accounts-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .account-item {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    width: 80%;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .profile-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 1rem;
  }
  
  .account-info {
    flex-grow: 1;
    text-align: left;
  }
  
  .disconnect {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  
  .disconnect:hover {
    background-color: #c0392b;
  }
/* dashboard*/
.dashboard-container {
        padding-left: 2rem;
        padding-right: 2rem;
}
/* Form Container */
.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* Takes up the full viewport height */
    padding: 1rem; /* Add padding for spacing */
    text-align: center; /* Center text within the form */
}

body.dark-mode .form-container {
    background-color: var(--background-dark);
}

.form-card {
    background-color:rgba(255, 255, 255, 0.1); ;
    padding: 2rem;
    border-radius: var(--border-radius-lg);
    box-shadow: var(--box-shadow);
    width: 100%;
    max-width: 400px;
}

body.dark-mode .form-card {
    background-color: var(--card-dark);
}

.form-card h2 {
    text-align: center; /* Centers the text */
    margin-bottom: 1rem; /* Adds space below the heading */
    font-size: 1.8rem; /* Adjusts the font size */
    font-weight: bold; /* Makes the text bold */
    color: var(--primary-color); /* Changes the text color to your primary color */
    letter-spacing: 1px; /* Adds some space between letters */
    padding: 0.5rem; /* Adds padding around the text */
    border-bottom: 2px solid var(--secondary-color);
    margin-top: 0px;
}

.form-card label {
    display: block;
    margin-bottom: 0.5rem;
}

.form-card input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
    margin-bottom: 1rem;
    box-sizing: border-box;
}

.form-card button {
    background-color: var(--primary-color);
    color: var(--background-light);
    padding: 0.75rem;
    border: none;
    border-radius: var(--border-radius);
    width: 100%;
    cursor: pointer;
}

.form-card button:hover {
    background-color: var(--secondary-color);
}

/* Footer */
.footer {
    background-color: var(--background-dark);
    color: var(--text-dark);
    padding: 2rem 0;
    text-align: center;
}

.footer p {
    margin: 0;
}

.footer a {
    color: var(--secondary-color);
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

/* Media Queries */
@media (min-width: 768px) {
    .feature-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1024px) {
    .feature-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .upload-page {
        max-width: 800px;
    }
}

/*************/
/* Upload Page */
/*************/

.upload-page {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: var(--card-light);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    border: 2px solid var(--primary-gradient); /* Add a border with gradient */
}

.media-buttons,
.post-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.media-preview {
    position: relative;
    margin-bottom: 1.5rem;
    border-radius: var(--border-radius);
    overflow: hidden;
    box-shadow: var(--box-shadow);
}

.media-preview img,
.media-preview video {
    width: 100%;
    display: block;
    border-radius: var(--border-radius); /* Add rounded corners */
}

.remove-media {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background-color: var(--error-color);
    color: white;
    border: none;
    border-radius: 9999px;
    width: 30px;
    height: 30px;
    font-size: 1rem;
    cursor: pointer;
    transition: var(--transition);
    display: flex;
    align-items: center;
    justify-content: center;
}

.remove-media:hover {
    transform: scale(1.1);
}

textarea,
input {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1.5rem;
    border: 2px solid var(--primary-color);
    border-radius: var(--border-radius);
    resize: vertical;
    font-family: inherit;
    font-size: 1rem;
    background-color: var(--card-light);
    color: var(--text-light);
    transition: var(--transition);
    box-sizing: border-box;
}

textarea:focus,
input:focus {
    outline: none;
    border-color: var(--secondary-color);
    box-shadow: 0 0 0 3px rgba(80, 227, 194, 0.3);
}

.platforms-container,
.alert-overlay,
.description-container {
    margin-bottom: 1.5rem;
}

.schedule-input {
    display: flex;
    box-sizing: border-box;
}

.description-container {
    display: flex;
    position: relative;
}

.platform-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: var(--background-dark);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: var(--border-radius-lg);
    cursor: pointer;
    transition: background-color var(--transition), border-color var(--transition);
}

.platform-item:hover {
    background-color: rgba(74, 144, 226, 0.8);
    color: var(--text-light);
    border-color: var(--primary-color);
}
.platform-item.selected {
    background-color: rgba(74, 144, 226, 1); /* Solid blue when selected */
    color: var(--text-light); /* Light text on blue background */
    border-color: var(--primary-color); /* Maintain border color */
}
.platform-name {
    margin-left: 0.5rem;
    font-size: 1.1em;
}

.platform-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 1rem;
}

.platform-tagging-popup {
    position: absolute;
    background-color: var(--card-light-solid);
    border: 1px solid var(--primary-color);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    z-index: 1000; /* Ensure it's above everything */
    max-height: 200px;
    overflow-y: auto;
    padding: 0.5rem 0;
  }
  
.platform-tag-item {
    padding: 0.75rem 1rem;
    cursor: pointer;
    transition: var(--transition);
}

.platform-tag-item:hover {
    background-color: var(--secondary-color);
    color: var(--text-dark);
}

.platform-icon {
    margin-right: 0.5rem;
    font-size: 1.2em;
}
.platform-settings {
    margin-bottom: 15px;
  }
  
  .character-limit-bar {
    background: #e0e0e0;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .filled-bar {
    background: #007bff;
    height: 100%;
  }

 /*Preview*/

.preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.preview-container {
  background: var(--card-light);
  border-radius: 8px;
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 20px;
}

.preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-preview {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.preview-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.platform-preview-card {
    background: var(--card-light);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

.platform-preview-header {
  background: var(--card-light-solid);
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.platform-icon {
  font-size: 1.2rem;
}

.platform-preview-content {
  padding: 15px;
}

.preview-media {
  width: 100%;
  margin-bottom: 15px;
  overflow: hidden;
  border-radius: 4px;
}

.preview-media img,
.preview-media video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview-text {
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.preview-stats {
  font-size: 0.8rem;
  color: #666;
}

.preview-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 20px;
  border-top: 1px solid #e1e1e1;
}

.btn-preview {
  background-color: #4a5568;
  color: white;
  margin-right: 10px;
}

.btn-preview:hover {
  background-color: #2d3748;
}



/************/
/*  Footer  */
/************/
.footer {
    background-color: #222;
    color: #fff;
    padding: 15px 20px; /* Adjusted padding for a sleeker look */
    text-align: center;
    font-size: 0.9rem; /* Consistent font size */
    margin-top: auto; /* Push the footer to the bottom */
  }
  
  .footer-content {
    margin-bottom: 10px; /* Space between content and bottom */
  }
  
  .footer-description {
    margin: 0; /* Remove default margin */
    font-size: 0.8rem; /* Slightly smaller for subtlety */
  }
  
  .footer-email a {
    color: #1da1f2; /* Link color */
    text-decoration: none; /* Remove underline */
  }
  
  .footer-email a:hover {
    text-decoration: underline; /* Underline on hover for accessibility */
  }
  
  .footer-links {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
    margin: 10px 0; /* Space around the links section */
  }
  
  .social-links {
    margin-bottom: 10px; /* Space below social links */
  }
  
  .social-links a {
    margin: 0 5px; /* Space between social links */
    color: #1da1f2; /* Link color */
    text-decoration: none; /* Remove underline */
  }
  
  .social-links a:hover {
    text-decoration: underline; /* Underline on hover for accessibility */
  }
  
  .legal-links {
    margin-top: 5px; /* Space above legal links */
  }
  
  .footer-bottom {
    border-top: 1px solid #444; /* Subtle separator */
    padding-top: 10px; /* Space above text */
    font-size: 0.9rem; /* Consistent font size */
  }
  
  



/* Alert Overlay */
.alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20; /* Ensure it overlays other content */
}

.alert-box {
    background-color: var(--card-light-solid);
    padding: 2rem;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    text-align: center;
}

/* Responsive styling */
@media (max-width: 768px) {
    .upload-page {
        padding: 1rem;
    }

    .media-buttons .btn,
    .post-options .btn {
        margin-bottom: 0.5rem;
    }
    .media-buttons {
        flex-direction: column;
    }
    .post-options {
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    .platform-grid {
        grid-template-columns: 1fr; /* Stack platforms on smaller screens */
    }

    .platform-item {
        justify-content: center; /* Center items in smaller screens */
    }

    .media-preview img,
    .media-preview video {
        max-width: 100%; /* Ensure media fits within container */
    }
}


























/* Common Styles */
.fb-post, .twitter-post, .instagram-post {
    background: white;
    border-radius: 8px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    max-width: 500px;
  }
  
  /* Avatar Placeholders */
  .fb-avatar-placeholder,
  .twitter-avatar-placeholder,
  .instagram-avatar-placeholder {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #e0e0e0;
  }
  
  /* Facebook Styles */
  .fb-post {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  }
  
  .fb-header {
    padding: 12px;
    display: flex;
    align-items: center;
  }
  
  .fb-avatar, .fb-avatar-placeholder {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .fb-user-info {
    flex-grow: 1;
  }
  
  .fb-name {
    font-weight: 600;
    color: #050505;
    display: block;
  }
  
  .fb-post-meta {
    color: #65676B;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .fb-content {
    padding: 0 12px 12px;
    font-size: 15px;
    line-height: 1.4;
    color: var(--text-dark);
  }
  
  .fb-media {
    margin-bottom: 12px;
  }
  
  .fb-media img, .fb-media video {
    width: 100%;
    display: block;
  }

  .fb-more{
    color: var(--text-dark);
}
  .fb-stats {
    padding: 0 12px;
    border-bottom: 1px solid #ced0d4;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #65676B;
  }
  
  .fb-actions {
    display: flex;
    padding: 4px;
    border-top: 1px solid #ced0d4;
  }
  
  .fb-action-btn {
    flex: 1;
    padding: 8px;
    border: none;
    background: none;
    color: #65676B;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
  }
  
  .fb-action-btn:hover {
    background: #F2F2F2;
    border-radius: 4px;
  }
  
  .fb-action-btn.liked {
    color: #1877F2;
  }
  
  /* Twitter Styles */
  .twitter-post {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    background: black;
    color: white;
  }
  
  .twitter-header {
    padding: 12px;
    display: flex;
    align-items: flex-start;
  }
  
  .twitter-avatar, .twitter-avatar-placeholder {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 12px;
  }
  
  .twitter-user-info {
    flex-grow: 1;
  }
  
  .twitter-names {
    display: flex;
    flex-direction: column;
  }
  
  .twitter-display-name {
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .twitter-verified {
    color: #1D9BF0;
    font-size: 14px;
  }
  
  .twitter-username {
    color: #71767B;
    font-size: 14px;
  }
  
  .twitter-content {
    padding: 0 12px 12px;
    font-size: 15px;
    line-height: 1.4;
  }
  
  .twitter-media {
    margin-bottom: 12px;
    border: 1px solid #2F3336;
    border-radius: 16px;
    overflow: hidden;
  }
  
  .twitter-media img, .twitter-media video {
    width: 100%;
    display: block;
  }
  
  .twitter-actions {
    display: flex;
    justify-content: space-around;
    padding: 12px;
    border-top: 1px solid #2F3336;
  }
  
  .twitter-action-btn {
    background: none;
    border: none;
    color: #71767B;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 13px;
    cursor: pointer;
  }
  
  .twitter-action-btn.liked {
    color: #F91880;
  }
  
  .twitter-action-btn.retweeted {
    color: #00BA7C;
  }
  
  .twitter-action-btn.bookmarked {
    color: #1D9BF0;
  }
  
  /* Instagram Styles */
  .instagram-post {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  }
  
  .instagram-header {
    padding: 14px;
    display: flex;
    align-items: center;
  }
  
  .instagram-avatar, .instagram-avatar-placeholder {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .instagram-username {
    font-weight: 600;
    flex-grow: 1;
    color: var(--text-dark);
  }
  
  .instagram-media {
    width: 100%;
    aspect-ratio: 1;
  }
  
  .instagram-media img, .instagram-media video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .instagam-more{
    color: var(--text-dark);
  }
  
  .instagram-actions {
    padding: 12px;
    display: flex;
    justify-content: space-between;
  }
  
  .instagram-primary-actions {
    display: flex;
    gap: 16px;
  }
  
  .instagram-action-btn {
    background: none;
    border: none;
    padding: 0;
    font-size: 24px;
    color: #262626;
    cursor: pointer;
  }
  
  .instagram-action-btn.liked {
    color: #ED4956;
  }
  
  .instagram-action-btn.bookmarked {
    color: #262626;
  }
  
  .instagram-likes {
    padding: 0 12px;
    font-weight: 600;
    font-size: 14px;
  }
  
  .instagram-caption {
    padding: 12px;
    font-size: 14px;
    line-height: 1.4;
    color: var(--text-dark);
  }
  
  .instagram-caption .instagram-username {
    font-weight: 600;
    margin-right: 6px;
  }
  
  /* LinkedIn Styles */
.linkedin-post {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  max-width: 500px;
}

.linkedin-header {
  padding: 12px;
  display: flex;
  align-items: flex-start;
}

.linkedin-avatar, .linkedin-avatar-placeholder {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
}

.linkedin-user-info {
  flex-grow: 1;
}

.linkedin-name {
  font-weight: 600;
  display: block;
  color: #000000E6;
}

.linkedin-title {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  display: block;
}

.linkedin-post-meta {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 4px;
}

.linkedin-content {
  padding: 0 12px 12px;
  font-size: 14px;
  line-height: 1.4;
}

.linkedin-media {
  margin-bottom: 8px;
}

.linkedin-media img, .linkedin-media video {
  width: 100%;
  display: block;
}

.linkedin-actions {
  display: flex;
  padding: 4px;
  border-top: 1px solid #e0e0e0;
}

.linkedin-action-btn {
  flex: 1;
  padding: 12px;
  border: none;
  background: none;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
}

.linkedin-action-btn:hover {
  background: rgba(0, 0, 0, 0.08);
}

.linkedin-action-btn.liked {
  color: #0a66c2;
}

/* YouTube Styles */
.youtube-post {
  font-family: Roboto, Arial, sans-serif;
  max-width: 500px;
  background: white;
}

.youtube-video-container {
  position: relative;
  width: 100%;
  background: black;
}

.youtube-video-container video {
  width: 100%;
  display: block;
}

.youtube-duration {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 3px 4px;
  border-radius: 2px;
  font-size: 12px;
}

.youtube-info {
  padding: 12px;
}

.youtube-header {
  display: flex;
  gap: 12px;
}

.youtube-avatar, .youtube-avatar-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.youtube-title {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 4px;
  line-height: 1.4;
}

.youtube-meta {
  font-size: 14px;
  color: #606060;
}

.youtube-channel {
  margin-right: 8px;
}

.youtube-actions {
  display: flex;
  gap: 8px;
  margin-top: 12px;
}

.youtube-action-btn {
  background: none;
  border: none;
  padding: 8px 16px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #606060;
  cursor: pointer;
}

.youtube-action-btn:hover {
  background: rgba(0, 0, 0, 0.05);
}

/* Threads Styles */
.threads-post {
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  max-width: 500px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  border: 1px solid #e5e5e5;
}

.threads-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 12px;
}

.threads-avatar, .threads-avatar-placeholder {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}

.threads-avatar-placeholder {
  background: #e5e5e5;
}

.threads-user-info {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 4px;
}

.threads-username {
  font-weight: 600;
  font-size: 14px;
}

.threads-verified {
  color: #000;
  font-size: 12px;
}

.threads-more {
  color: #666;
  cursor: pointer;
  padding: 8px;
}

.threads-content {
  font-size: 15px;
  line-height: 1.4;
  margin-bottom: 12px;
  white-space: pre-wrap;
}

.threads-media {
  margin: 12px -16px;
  position: relative;
}

.threads-media img, 
.threads-media video {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}

.threads-actions {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 16px;
  padding-top: 12px;
  border-top: 1px solid #f0f0f0;
}

.threads-action-btn {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: #262626;
  font-size: 20px;
  transition: color 0.2s;
}

.threads-action-btn:hover {
  color: #666;
}

.threads-action-btn.liked {
  color: #ff3040;
}

.threads-action-btn.reposted {
  color: #00cc00;
}

/* TikTok Styles */
.tiktok-post {
  background: #000;
  width: 100%;
  max-width: 340px;
  height: 600px;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  color: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.tiktok-video-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.tiktok-video {
  width: 100%;
  height: 100%;
  position: relative;
}

.tiktok-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tiktok-video-controls {
  position: absolute;
  bottom: 20px;
  left: 12px;
  display: flex;
  gap: 16px;
  z-index: 2;
}

.tiktok-play-btn,
.tiktok-mute-btn {
  background: rgba(0, 0, 0, 0.4);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s;
}

.tiktok-play-btn:hover,
.tiktok-mute-btn:hover {
  background: rgba(0, 0, 0, 0.6);
}

.tiktok-sidebar {
  position: absolute;
  right: 12px;
  bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 2;
}

.tiktok-action-btn {
  background: none;
  border: none;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.tiktok-action-btn svg {
  font-size: 24px;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
}

.tiktok-action-btn span {
  font-size: 12px;
  font-weight: 600;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
}

.tiktok-action-btn.liked {
  color: #ff3040;
}

.tiktok-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 12px;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
  z-index: 1;
}

.tiktok-user-info {
  margin-bottom: 8px;
}

.tiktok-username {
  font-weight: 600;
  font-size: 16px;
  margin-right: 8px;
}

.tiktok-name {
  font-size: 14px;
  opacity: 0.9;
}

.tiktok-description {
  font-size: 14px;
  margin-bottom: 8px;
  line-height: 1.4;
}

.tiktok-music {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  opacity: 0.9;
}

.tiktok-music svg {
  font-size: 12px;
}




.preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  z-index: 1000;
  overflow: hidden;
}

.preview-container {
  background-color: var(--card-light);
  border-radius: 8px;
  padding: 20px;
  width: 80%;
  max-width: 900px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.preview-grid {
  display: flex;
  align-items: center;
  justify-content: center; /* Centering the grid items */
  position: relative;
}

.left-arrow,
.right-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/* Adjust as needed for arrow placement */
.left-arrow {
  left: -30px;
}

.right-arrow {
  right: -30px;
}

.platform-indicators {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.indicator {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0px;
  background: var(--card-light-solid);
  transform: 2px;

}

.indicator.active {
  background-color: #007bff;
}

.preview-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}


.settings-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.settings-section {
  background: var(--card-light);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.settings-section h2 {
  color: var(--text-light);
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--card-light-solid);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: var(--text-light);
  font-weight: 500;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.form-group input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;
}

button:hover {
  background-color: #0056b3;
}

.error-message {
  color: #dc3545;
  margin: 10px 0;
  padding: 10px;
  background-color: #fde8e8;
  border-radius: 4px;
}

.success-message {
  color: #28a745;
  margin: 10px 0;
  padding: 10px;
  background-color: #e8f5e9;
  border-radius: 4px;
}

.profile-preview {
  margin-top: 10px;
}

.profile-preview img {
  max-width: 150px;
  border-radius: 50%;
  border: 2px solid #ddd;
}

.delete-section {
  border: 1px solid #ffcdd2;
}

.delete-button {
  background-color: #dc3545;
}

.delete-button:hover {
  background-color: #c82333;
}

.delete-confirm {
  background-color: #fff8f8;
  padding: 15px;
  border-radius: 4px;
  margin-top: 10px;
}

.delete-confirm p {
  color: #dc3545;
  margin-bottom: 15px;
}

.delete-actions {
  display: flex;
  gap: 10px;
}

.cancel-button {
  background-color: #6c757d;
}

.cancel-button:hover {
  background-color: #5a6268;
}

/* Responsive Design */
@media (max-width: 600px) {
  .settings-container {
    padding: 10px;
  }

  .settings-section {
    padding: 15px;
  }

  .delete-actions {
    flex-direction: column;
  }

  .delete-actions button {
    width: 100%;
  }
}

/* Profile Image in Circular Format */
.circular-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin: 0 auto; /* Center the image */
  display: block;
}

/* Fullscreen Popup for Cropping */
.crop-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it appears on top of other content */
  backdrop-filter: blur(8px); /* Smooth blur effect */
  animation: fadeIn 0.3s ease-in-out;
}

/* Main Crop Tool Container */
.crop-container {
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  width: 90%;
  height:70%;
  max-width: 500px;
  max-height: 300px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

/* Buttons and Controls for Cropping */
.crop-controls {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.crop-controls button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.crop-controls button:hover {
  background-color: #45a049;
}

.crop-controls button.cancel-btn {
  background-color: #f44336;
}

.crop-controls button.cancel-btn:hover {
  background-color: #e53935;
}

/* Smooth Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Ensures Responsiveness on Mobile */
@media (max-width: 600px) {
  .crop-container {
    width: 95%;
    padding: 20px;
  }

  .crop-controls button {
    font-size: 14px;
    padding: 8px 16px;
  }
}